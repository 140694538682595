import { Box, CircularProgress, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined } from "@material-ui/icons";
import QRCode from "qrcode.react";
import { useAccount } from "wagmi";
import CustomConnectButton from "src/component/CustomConnectButton";
import { sortAddress, NetworkID } from "src/utils";
import toast from "react-hot-toast";
import { useWriteContract } from "src/hooks/useWriteContract";

const MemoizedCustomConnectButton = memo(CustomConnectButton);

const PayCrypto = ({
  equivalentData,
  selectCurrency,
  selectCurrencyObject,
  classes,
}) => {
  const openZendesk = () => {
    if (window.zE) {
      window.zE("messenger", "open");
    } else {
      console.error("Zendesk widget is not loaded yet.");
    }
  };
  const payAddress = equivalentData?.pay_address;
  const { isConnected, address, connector, chainId } = useAccount();

  const { sendEth, sendErc20Token } = useWriteContract();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 60000);
    }
    // if (success) {
    //   setTimeout(() => {
    //     setSuccess(null);
    //   }, 60000);
    // }
  }, [error, success]);

  const handlePayETH = async (recipient, amount) => {
    setLoading(true);
    const response = await sendEth(recipient, amount);
    setLoading(false);
    if (response?.error) {
      setError(response?.error);
    }
    if (response?.success) {
      setSuccess("Transaction successful! Waiting for confirmation...");
    }
  };
  const handlePayEToken = async (tokenAddress, recipient, amount, decimals) => {
    setLoading(true);
    const response = await sendErc20Token(
      tokenAddress, // "0x4B23fd946EfA27324ED17861c450DA3e70dC3D45", //tokenAddress
      recipient, //"0xA0Ae9ECb5f0E58E450A4273d333696Da6A670C17", // recipient
      amount, //"1", //amount
      decimals // decimals
    );

    setLoading(false);
    if (response?.success) {
      setSuccess("Transaction successful! Wait for confirmation.");
    }
    if (response?.error) {
      setError(response?.error);
    }
  };

  const handlePay = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    let tx;
    try {
      const recipient = payAddress; //---- payAddress

      if (selectCurrencyObject.smart_contract) {
        // Sending token
        if (selectCurrencyObject.network_precision) {
          const tokenAddress = selectCurrencyObject.smart_contract; // selectCurrencyObject.smart_contract
          handlePayEToken(
            tokenAddress,
            recipient,
            equivalentData?.pay_amount.toString(),
            selectCurrencyObject?.network_precision || 18
          );
        }
      } else {
        // Sending ETH
        handlePayETH(recipient, equivalentData?.pay_amount.toString());
      }
    } catch (err) {
      console.error("err", err);
      toast.error("Transaction failed");
      setLoading(false);
      setError("Transaction failed");
    }
  };
  let isShowWallet = selectCurrencyObject?.network.includes("bsc")
    ? true
    : selectCurrencyObject?.network &&
      selectCurrencyObject?.network_precision &&
      NetworkID(selectCurrencyObject?.network) &&
      !selectCurrencyObject.smart_contract
    ? true
    : selectCurrencyObject?.network &&
      selectCurrencyObject?.network_precision &&
      NetworkID(selectCurrencyObject?.network) &&
      selectCurrencyObject.smart_contract
    ? true
    : false;
  const isWrongNetwork = chainId !== NetworkID(selectCurrencyObject?.network);
  return (
    <Box>
      <Box mt={2} className="displayCenter">
        <QRCode value={payAddress} size={140} />
      </Box>
      <Box mt={2} className="flex flex-col gap-1">
        <p className="text-left font-extrabold text-white text-[12px]">
          AMOUNT
        </p>
        <div className="p-2 text-center rounded-lg flex items-center justify-between shadow-inner border-2 border-dashed border-gray-500">
          <Typography
            variant="body2"
            color="primary"
            className="flex pr-2 gap-2 font-[500] text-[12px]"
          >
            {equivalentData?.pay_amount ? equivalentData?.pay_amount : "0"}{" "}
            {selectCurrency}
          </Typography>
          {equivalentData?.pay_amount > 0 && (
            <CopyToClipboard text={equivalentData?.pay_amount}>
              <span className="hover:opacity-90 active:scale-95 flex items-center rounded-md flex justify-center gap-2 text-2xl text-[#ffffff]">
                <FileCopyOutlined
                  className="cursor-pointer text-[#fff] text-[12px] hover:text-[#ffffff] hover:shadow-lg hover:shadow-yellow-400/50"
                  onClick={() => toast.success("Copied")}
                />
              </span>
            </CopyToClipboard>
          )}
        </div>
      </Box>
      <Box mt={2} className="flex flex-col gap-1">
        <p className="text-left font-extrabold text-white text-[12px]">
          PAYING ADDRESS
        </p>
        <div className="p-2 text-center rounded-lg flex items-center justify-between shadow-inner border-2 border-dashed border-gray-500">
          <Typography
            variant="body2"
            color="primary"
            className="flex pr-2 gap-2 font-[500] text-[12px]"
          >
            {sortAddress(payAddress)}
          </Typography>
          {payAddress && (
            <CopyToClipboard text={payAddress}>
              <span className="hover:opacity-90 active:scale-95 flex items-center rounded-md flex justify-center gap-2 text-2xl text-[#ffffff]">
                <FileCopyOutlined
                  className="cursor-pointer text-[#fff] text-[12px] hover:text-[#ffffff] hover:shadow-lg hover:shadow-yellow-400/50"
                  onClick={() => toast.success("Copied")}
                />
              </span>
            </CopyToClipboard>
          )}
        </div>
        <Box pt={1} pb={1} className="flex flex-col gap-1 align-center">
          <p className="text-center font-extrabold text-[#8c8faf] text-[12px]">
            Copy "AMOUNT" and "ADDRESS" and pay through your wallet.
          </p>
          <p className="text-center font-extrabold text-[#ffffff] text-[18px]">
            or
          </p>
          <p className="text-center font-extrabold text-[#8c8faf] text-[12px]">
            Connect & pay directy with your wallet
          </p>
        </Box>
      </Box>

      {isShowWallet && (
        <Box
          className={`flex flex-col gap-1 ${
            loading ? "opacity-50 cursor-not-allowed" : "pointer-events-auto"
          }`}
        >
          {isWrongNetwork && isConnected && (
            <div className={` ${loading && "pointer-events-auto"}`}>
              <MemoizedCustomConnectButton
                payChainID={NetworkID(selectCurrencyObject?.network)}
                networkName={selectCurrencyObject?.network.toUpperCase()}
              />
            </div>
          )}
          {!isConnected && (
            <div className={` ${loading && "pointer-events-auto"}`}>
              <MemoizedCustomConnectButton
                payChainID={NetworkID(selectCurrencyObject?.network)}
                networkName={selectCurrencyObject?.network.toUpperCase()}
              />
            </div>
          )}
          {NetworkID(selectCurrencyObject?.network) == chainId && (
            <button
              type="button"
              onClick={() => {
                handlePay();
              }}
              className={`text-[16px] bg-[#5aeb50] w-full p-1 text-center rounded-lg flex items-center justify-center gap-2 text-2xl text-[#ffffff] hover:opacity-90 active:scale-95 ${
                !loading && success === null
                  ? "pointer-events-auto"
                  : "opacity-80 cursor-not-allowed pointer-events-none"
              }`}
            >
              {success === null ? "Pay now" : "Confirm transaction"}
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={20}
                  style={{ color: "#ffffff" }}
                />
              )}
            </button>
          )}
          {/* </Box> */}
          {error && (
            <p className="text-left font-extrabold text-[red] font-[500] text-[13px]">
              {error}
            </p>
          )}
          {success && (
            <p className="text-left font-extrabold text-[green] font-[500] text-[13px]">
              {success}
            </p>
          )}
          <p className="text-left font-extrabold text-[#8c8faf] text-[11px]">
            Pay securely using your MetaMask wallet.
          </p>
        </Box>
      )}
      <Box mt={2} className={`${classes.depositeQR} displayColumn`}>
        <Box textAlign="center">
          <Typography
            variant="body2"
            color="secondary"
            style={{ color: "#8C8FAF", fontSize: "12px" }}
          >
            Need deposit assistance? <br />
            <span className="link" onClick={() => openZendesk()}>
              Click here
            </span>{" "}
            to connect with our LIVE agent. <br />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PayCrypto);
