import bgSound from "../assets/sounds/bgMusic.mp3";
import diceRoll from "../assets/sounds/Diceroll.mp3";
import cuttingSound from "../assets/sounds/cuttingSound.mp3";
import cuttingBack from "../assets/sounds/cuttingBack.mp3";
import pawnMove from "../assets/sounds/Jump.mp3";
import win from "../assets/sounds/win.mp3";
import loose from "../assets/sounds/loose.mp3";
import spinStart from "../assets/sounds/spinStart.mp3";
import spinEnd from "../assets/sounds/spinSlow.mp3";
import winClap from "../assets/sounds/winClap.mp3";
import claimReward from "../assets/sounds/claimReward.wav";
import gameStartSound from "../assets/sounds/gameStartSound.mp3";
import musicWav from "../assets/sounds/music.wav";
import { Howl, Howler } from 'howler';

export class GameSound {
  constructor() {
    this.bgAudio = new Howl({
      src: bgSound,
      loop: true,
      volume: 0.5,
      preload: true,
      autoplay: false
    })
    this.gameAudio = new Howl({
      src: [diceRoll]
    });
    this.cutAudio = new Howl({
      src: [cuttingSound]
    });
    this.swipAudio = new Howl({
      src: [cuttingBack]
    });
    this.pawnMove = new Howl({
      src: [pawnMove]
    });
    this.winSound = new Howl({
      src: [win],
    });
    this.looseSound = new Howl({
      src: [loose],
    });
    this.gameStartSound = new Howl({
      src: [gameStartSound],
    })

    this.musicWav = new Howl({
      src: [musicWav],
    })
    this.spinStart = new Howl({
      src: [spinStart],
    })
    this.spinEnd = new Howl({
      src: [spinEnd],
    })
    this.winClap = new Howl({
      src: [winClap],
    })
    this.musicWav = new Howl({
      src: [musicWav],
    })
    this.claimReward = new Howl({
      src: [claimReward],
    })
    this.looseSound = new Howl({
      src: [loose],
    });
    this.gameStartSound = new Howl({
      src: [gameStartSound],
    })
  }

  setVolume(type, value = 0.5) {
    switch (type) {
      case "music": {
        // this.audio.volume = value;
        break;
      }
      case "dice": {
        this.gameAudio.volume = value;
        break;
      }
      default: {
        console.error("invalid type");
      }
    }
  }

  updateVolume(val) {
    this.gameAudio.volume(val)
    this.cutAudio.volume(val)
    this.swipAudio.volume(val)
    this.pawnMove.volume(val)
  }

  updateBgVolume(val) {
    this.bgAudio.volume(val)
  }

  playdiceSound() {
    this.gameAudio.play();
  }

  playCuttingSound() {
    this.cutAudio.play();
  }
  playSwipSound() {
    this.swipAudio.play();
  }

  playPawnMove() {
    this.pawnMove.play();
  }
  pausePawnMove() {
    this.pawnMove.pause();
  }
  playBgAudio() {
    this.bgAudio.play();
  }
  pauseBgAudio() {
    this.bgAudio.pause();
  }
  playWinSound() {
    this.winSound.play();
  }
  pauseWinSound() {
    this.winSound.pause();
  }

  playLooseSound() {
    this.looseSound.play();
  }
  pauseLooseSound() {
    this.looseSound.pause();
  }

  playGameStartSound() {
    console.log("playgin.....")
    this.gameStartSound.play()
  }
  earnBonus() {
    console.log("playgin.....")
    this.musicWav.play()
  }

  pauseGameStartSound() {
    this.gameStartSound.pause();
  }
}


