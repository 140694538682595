import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const useApi = (initialUrl = "", initialOptions = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = window.sessionStorage.getItem("token");
  const fetchData = useCallback(
    async (url, options) => {
      setLoading(true);
      setError(null);

      const headers = {
        ...options.headers,
        token: token ? token : undefined,
      };
      if (!token) {
        return;
      }
      try {
        const response = await axios({
          url,
          ...options,
          headers, // Attach the headers including the token
        });
        setData(response.data);
      } catch (err) {
        setError(err);
        console.log(err?.response?.data?.responseCode);
        console.log(err?.response);
        if (err?.response?.data?.responseCode === 403) {
          toast.error(err?.response?.data?.responseMessage);
          window.sessionStorage.removeItem("token");
          window.sessionStorage.removeItem("ConnectWallet");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          window.localStorage.removeItem("ADMIN");
          window.localStorage.removeItem("referralCode");
          window.localStorage.removeItem("userID");
          window.localStorage.removeItem("profilePic");
          window.localStorage.removeItem("fullName");
          window.localStorage.removeItem("userName");
        }
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  // Automatically fetch data if initialUrl is provided
  useEffect(() => {
    if (initialUrl) {
      fetchData(initialUrl, initialOptions);
    }
  }, [initialUrl, initialOptions, fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export default useApi;
