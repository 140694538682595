import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { AuthContext } from "src/context/Auth";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { getQueryParams } from "../../helper/getQueryParam";
import { TopBarData } from "./TopBarData";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 5px 0px",
    "& .TopbarButton": {
      marginRight: "0px !important",
      margin: "0px !important",
    },
  },
}));

export default function Header() {
  const { toolbar } = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.userData.refferalCodeCheck) {
      auth.setOpenReferralModal(true);
    }
  }, [auth.userData.refferalCodeCheck]);

  useEffect(() => {
    if (location) {
      window.localStorage.setItem("referralCode", getQueryParams("refCode"));
    }
  }, [location]);

  return (
    <AppBar
      position={window.location.pathname !== "/" ? "relative" : "absolute"}
      elevation={0}
      style={{
        backgroundColor: "#191B30",
        borderBottom: "1px solid #2C2C2C",
      }}
      className="hedaerTopbar"
    >
      <Container maxWidth="fixed">
        <Toolbar className={toolbar}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <IconButton
              variant="contained"
              color="secondary"
              size="small"
              style={{ whiteSpace: "pre" }}
              onClick={() =>
                window.location.pathname === "/leaderboard"
                  ? history("/derby")
                  : window.location.pathname === "/privacy-policy" ||
                    window.location.pathname === "/terms-condition"
                  ? history("/")
                  : window.location.pathname === "/h2h"
                  ? history("/")
                  : history(-1)
              }
            >
              <MdOutlineArrowBackIos />
            </IconButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <IconButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => history("/")}
              >
                <AiFillHome />
              </IconButton> */}
            <Link to="/">
              <Logo className="logoImg" />
            </Link>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
            <TopBarData />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
