import { currencyUnit, virtualCurrency } from "src/constants";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  makeStyles,
  InputAdornment,
  IconButton,
  FormHelperText,
  Slide,
  Paper,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { handleNegativeValue, sortAddress } from "src/utils";
import { RxCross2 } from "react-icons/rx";
import { postAPIHandler } from "src/ApiConfig/service";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useDebounce from "src/component/customHook/Debounce";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import { AuthContext } from "src/context/Auth";
import { useRef, useEffect } from "react";
import LazyImage from "src/component/LazyImage";
import { memo } from "react";
import PayCrypto from "./PayCrypto";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { useDisconnect } from "wagmi";

const CustomAlert = withStyles({
  root: {
    background: "linear-gradient(90deg, #ff7e5f, #feb47b)", // Gradient effect
    color: "#fff", // White text
    fontWeight: "bold",
    borderRadius: "8px", // Rounded corners
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Shadow for a cool look
    margin: "16px 0",
  },
  icon: {
    color: "#fff", // White icon
  },
})(Alert);

const MemoizedPayCrypto = memo(PayCrypto);

const CustomPaper = (props) => <Paper {...props} elevation={1} />;
export default function ModalDeposit({
  closeBox,
  getAPIHandler,
  axios,
  viewWalletData,
  openDepositFlow,
  setOpenDepositFlow,
  open,
  prePathname,
}) {
  const modalRef = useRef(null);
  // const location = useLocation(); // Hook to get the current route
  const { disconnect } = useDisconnect();

  const handleLogout = () => {
    disconnect();
    localStorage.removeItem("wagmi.connected");
  };

  useEffect(() => {
    // Close the modal when the route changes
    if (window.location.pathname !== prePathname) {
      closeBox();
      handleLogout();
    }
  }, [window.location.pathname]); // Run this effect whenever the route changes

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        open ? "visible opacity-100" : "invisible opacity-0"
      } bg-black bg-opacity-80 transition-opacity duration-300 ease-in-out`}
    >
      <div
        ref={modalRef}
        className="rounded-lg shadow-lg relative m-4 w-[800px] max-h-[90vh] overflow-y-auto"
        style={{ maxWidth: "100%" }}
      >
        <DepositDialogContent
          closeBox={() => {
            closeBox();
            handleLogout();
          }}
          getAPIHandler={getAPIHandler}
          axios={axios}
          viewWalletData={viewWalletData}
          openDepositFlow={openDepositFlow}
          setOpenDepositFlow={setOpenDepositFlow}
          open={open}
        />
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  FormDatas: {
    "& form": {
      padding: "22px !important",
    },
  },
  LoosePopModalBox: {
    // position: "relative",

    "& h6": {
      color: "#fff",
    },
    "& .imgBox": {
      padding: "50px 0px 30px",
      "& img": {
        width: "100px",
      },
    },
  },
  sideContent: {
    textAlign: "center",
  },
  depositeQR: {
    " & span": {
      color: "#fff",
    },
    "& .link": {
      color: "navajowhite",
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": { opacity: "0.8" },
    },
  },
  formControl: {
    "& .MuiAutocomplete-popper": {
      "& .MuiPaper-elevation1": {
        padding: "0px !important",
      },
    },
  },
}));

const DepositDialogContent = ({
  closeBox,
  getAPIHandler,
  axios,
  setOpenDepositFlow,
}) => {
  const classes = useStyle();
  // const [isSelectCrypto, setIsSelectCrypto] = useState(true);
  const [isSelectCrypto1, setIsSelectCrypto1] = useState(true);
  const [selectCurrencyObject, setSelectCurrencyObject] = useState();
  const [selectCurrency, setSelectCurrency] = useState("");
  const [amountValue, setAmountValue] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [equivalentData, setEquivalentData] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [equivalentPayData, setEquivalentPayData] = useState();
  const [isCurrencySelected, setCurrencySelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coinListData, setCoinListData] = useState([]);
  const deb = useDebounce(amountValue, 500);
  const deb1 = useDebounce(selectCurrency, 500);
  const { openSupport, setOpenSupport, bonus200Check, publicAddress, isSelectCrypto, setIsSelectCrypto } =
    useContext(AuthContext);
  const checkEtherCoin =
    selectCurrency === "USDTERC20" || selectCurrency === "BTC";
  const getCoinListApi = async (source) => {
    try {
      const response2 = await getAPIHandler({
        endPoint: "getCoin2",
        source: source,
      });
      if (response2?.data?.responseCode === 200) {
        setCoinListData(response2?.data?.result);
        // const newValue = response2?.data?.result[6];
        // setSelectCurrencyObject(newValue); //-------------------- remove this
        // setSelectCurrency(newValue?.code); //-------------------- remove this
      } else {
        setCoinListData();
      }
    } catch (error) {
      console.log(error);
      setCoinListData();
    }
  };

  const getDepositAmoutApi = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getDepositBalence",
        paramsData: {
          fromCoin: virtualCurrency.toLocaleLowerCase(),
          toCoin: selectCurrency.toLocaleLowerCase(),
          amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setEquivalentData(response?.data?.result);
      } else {
        setEquivalentData();
        setIsLoading(false);
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setEquivalentData();
      setIsLoading(false);
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };
  const getDepositMinAmoutApi = async () => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getMinimumDeposite",
        paramsData: {
          fromCoin: selectCurrency.toLocaleLowerCase(),
          toCoin: virtualCurrency.toLocaleLowerCase(),
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setMinDepositAmount(response?.data?.result?.fiat_equivalent);
      } else {
        setMinDepositAmount();
        setIsLoading(false);
      }
    } catch (error) {
      setMinDepositAmount();
      setIsLoading(false);
      console.log(error);
    }
  };
  const sendDepositMoney = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "depositMoney",
        dataToSend: {
          pay_currency: selectCurrency.toLocaleLowerCase(),
          price_amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setEquivalentPayData(response?.data?.result);
        setIsLoading(false);
        setCurrencySelected(true);
      } else {
        setIsLoading(false);
        setEquivalentPayData();
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setEquivalentPayData();
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };

  useEffect(() => {
    deb !== "" &&
      selectCurrency !== "" &&
      !checkEtherCoin &&
      amountValue >= minDepositAmount &&
      getDepositAmoutApi();
  }, [deb, selectCurrency, deb1]);

  useEffect(() => {
    selectCurrency !== "" && getDepositMinAmoutApi();
  }, [selectCurrency]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getCoinListApi(source);
    return () => {
      source.cancel();
    };
  }, []);
  return (
    <Box className={classes.FormDatas}>
      <Box className={`container`} id="container">
        <RxCross2
          className="closeIcon"
          style={{ color: "#fff", cursor: "pointer" }}
          onClick={() => {
            closeBox();
            setSelectCurrency("");
            setAmountValue("");
            setEquivalentData();
          }}
        />
        <Box className="form-container deposit-pay">
          <form action="#" style={{ padding: "16px !important" }}>
            {!isCurrencySelected && (
              <div className="flex justify-between">
                <button
                  type="button"
                  className={`relative depositButton w-1/2 font-extrabold bg-[${
                    !isSelectCrypto ? "b" : ""
                  }] px-8 py-2 text-[18px] text-[#fff] leading-6 whitespace-pre font-readex`}
                  onClick={() => setIsSelectCrypto(true)}
                  id="crypto-deposit-btn"
                >
                  CRYPTO
                  {isSelectCrypto && (
                    <span className="absolute bottom-[-3px] left-0 w-full h-[4px] bg-gradient-to-r from-[#ffca64] via-[#d88b4a] to-[#ca772a]"></span>
                  )}
                </button>
                <button
                  type="button"
                  className={`relative depositButton w-1/2 font-extrabold bg-[${
                    isSelectCrypto ? "b" : ""
                  }] px-8 py-2 text-[18px] text-[#fff] leading-6 whitespace-pre font-readex`}
                  onClick={() => setIsSelectCrypto(false)}
                >
                  CARD
                  {!isSelectCrypto && (
                    <span className="absolute bottom-[-3px] left-0 w-full h-[4px] bg-gradient-to-r from-[#ffca64] via-[#d88b4a] to-[#ca772a]"></span>
                  )}
                </button>
              </div>
            )}
            {isSelectCrypto ? (
              <div>
                {isCurrencySelected ? (
                  <MemoizedPayCrypto
                    equivalentData={equivalentPayData}
                    selectCurrency={selectCurrency}
                    openSupport={openSupport}
                    setOpenSupport={(e) => setOpenSupport(e)}
                    selectCurrencyObject={selectCurrencyObject}
                    classes={classes}
                  />
                ) : (
                  <>
                    <Box
                      mb={2}
                      mt={3}
                      className={!isSelectCrypto1 ? "hidden" : ""}
                    >
                      <Box className="SelectorBox">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Autocomplete
                            freeSolo
                            options={coinListData || []}
                            getOptionLabel={(option) => option?.code || ""}
                            value={
                              selectCurrency ? { code: selectCurrency } : null
                            }
                            disabled={isLoading}
                            filterOptions={(options, state) => {
                              const filtered = options.filter((option) =>
                                option?.code
                                  ?.toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );

                              return filtered.length > 0
                                ? filtered
                                : ["No matching coins found"];
                            }}
                            renderOption={(option) =>
                              option === "No matching coins found" ? (
                                <MenuItem
                                  disabled
                                  style={{
                                    fontWeight: "bold",
                                    color: "red",
                                    textAlign: "center",
                                    width: "100%",
                                    padding: "8px 16px", // Reduce padding for a compact look
                                  }}
                                >
                                  🚨 No matching coins found
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  style={{
                                    padding: "2px 2px",
                                    maxHeight: "25px",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "8px",
                                      padding: "6px 12px",
                                      height: "25px",
                                    }}
                                  >
                                    <LazyImage
                                      src={`https://nowpayments.io${option?.logo_url}`}
                                      height="20px"
                                      width="20px"
                                      alt={option?.code}
                                      style={{ borderRadius: "50%" }}
                                    />
                                    {option?.code}
                                  </Box>{" "}
                                </MenuItem>
                              )
                            }
                            PaperComponent={CustomPaper}
                            onChange={(event, newValue) => {
                              if (newValue !== "No matching coins found") {
                                setSelectCurrency(
                                  newValue?.code?.toUpperCase() || ""
                                );
                                setSelectCurrencyObject(newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select coin"
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        placeholder={
                          checkEtherCoin
                            ? "Amount you will be paying"
                            : "Enter Amount"
                        }
                        value={amountValue}
                        disabled={selectCurrency === "" || isLoading}
                        onChange={(e) => setAmountValue(e.target.value)}
                        onKeyDown={(event) => handleNegativeValue(event)}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "*" ||
                            event?.key === "/"
                          ) {
                            event.preventDefault();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography
                                variant="body1"
                                style={{ color: "#f4a91b" }}
                              >
                                {" "}
                                {currencyUnit}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {amountValue !== "" &&
                          amountValue < minDepositAmount &&
                          `Please enter greater than ${parseFloat(
                            minDepositAmount
                          ).toFixed(3)} ${currencyUnit}`}
                      </FormHelperText>
                      {(selectCurrency === "USDTERC20" ||
                        selectCurrency === "BTC") && (
                        <Box mt={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "12px",
                              lineHeight: "1.25",
                              letterSpacing: "0.000938em",
                            }}
                          >
                            The equivalent amount of {currencyUnit} that you
                            receive is reflected on Afterpay transactions.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    {!checkEtherCoin && (
                      <Box mb={1}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled
                          value={equivalentData?.estimated_amount}
                          type="number"
                          placeholder="Amount you will be paying"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {selectCurrency !== "" && selectCurrency}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    <Box mt={2}>
                      <FormHelperText error>
                        {errorMsg !== "" && errorMsg}
                      </FormHelperText>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        id="card-deposit-btn"
                        disabled={
                          amountValue < minDepositAmount ||
                          amountValue === "" ||
                          (!checkEtherCoin && !equivalentData) ||
                          isLoading ||
                          selectCurrency === ""
                        }
                        onClick={() => {
                          if (isSelectCrypto1) {
                            sendDepositMoney();
                          } else {
                            setIsSelectCrypto(false);
                          }
                        }}
                      >
                        {isLoading
                          ? "Loading..."
                          : isSelectCrypto1
                          ? "Pay by crypto"
                          : "Pay by card"}
                      </Button>
                    </Box>
                    <iframe
                      className="mt-3 rounded-lg"
                      height="200"
                      width="100%"
                      src="https://www.youtube.com/embed/xxHa2TydxLs?si=RRiE0rFgowZ44kC3"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                  </>
                )}
              </div>
            ) : (
              <div className="widget-container h-96 pt-2">
                <iframe
                  width="100%"
                  height="100%"
                  style={{ minHeight: "400px" }}
                  frameborder="none"
                  allow="camera"
                  src={`https://widget.changelly.com?from=usd&to=usdtbsc&amount=50&address=${publicAddress}&fromDefault=usd&toDefault=usdtbsc&merchant_id=_o3HMYC2e4KPLnP_&payment_id=&v=3&type=no-rev-share&color=f9861b&headerId=2&logo=hide&buyButtonTextId=4&readOnlyDestinationAddress=true`}
                >
                  Can't load widget
                </iframe>{" "}
                <CustomAlert icon={<CardGiftcardIcon />} severity="info">
                  In addition to the above, get up to{" "}
                  <strong>200% USDT bonus</strong> on your first deposit – don't
                  miss out!
                </CustomAlert>
              </div>
            )}
          </form>
        </Box>
        <Box className="deposit-right">
          <Box className="overlay">
            <Box className={`overlay-panel overlay-right textLeft`}>
              <RxCross2
                className="closeIcon"
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={() => {
                  closeBox();
                  setSelectCurrency("");
                  setAmountValue("");
                  setEquivalentData();
                }}
              />
              <Box p={2} className={classes.sideContent}>
                <img
                  src="images/logo.png"
                  alt="BetbyHeart"
                  style={{ maxWidth: "135px", margin: "auto" }}
                />

                {bonus200Check ? (
                  <Box>
                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{ color: "#8C8FAF", marginTop: "2rem" }}
                    >
                      Kickstart your journey with a bang!
                    </Typography>

                    <Typography
                      variant="body2"
                      color="secondary"
                      style={{ color: "#8C8FAF", marginTop: "10px" }}
                    >
                      Make your first deposit now and get up to 200% bonus
                      instantly! 🎉💰 Don’t miss this exclusive welcome
                      boost—grab it now!
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ color: "#8C8FAF", marginTop: "2rem" }}
                  >
                    Deposit funds into your internal wallet with absolute
                    security.
                  </Typography>
                )}
                <Box mt={4}>
                  {bonus200Check ? (
                    <img
                      src="/images/bonus_banner.webp"
                      alt="BetbyHeart"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <img
                      src="images/deposit.png"
                      alt="BetbyHeart"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
