import { Contract } from "@ethersproject/contracts";
import Web3 from "web3";
import { RPC_URL } from "src/constants";
import { postAPIHandler, getAPIHandler } from "src/ApiConfig/service";
import { toast } from "react-hot-toast";
import moment from "moment";
import * as XLSX from "xlsx";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}
export function NetworkID(name) {
  const network = networkArray.find((item) => item.match === name);
  return network?.value;
}
const networkArray = [
  {
    match: "eth",
    name: "Ethereum",
    value: 1,
  },
  {
    match: "bsc",
    name: "BSC",
    value: 56,
  },
];
export function formatNumber(value) {
  if (value < 1000) {
    return value.toString(); // Less than 1000, return the number as it is
  } else if (value >= 1000 && value < 1_000_000) {
    return (value / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Thousands
  } else if (value >= 1_000_000 && value < 1_000_000_000) {
    return (value / 1_000_000).toFixed(1).replace(/\.0$/, "") + "m"; // Millions
  } else if (value >= 1_000_000_000 && value < 1_000_000_000_000) {
    return (value / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "b"; // Billions
  } else {
    return (value / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "t"; // Trillions
  }
}

export function getShortTeamName(teamName) {
  // Trim the team name and split it into words
  const words = teamName.trim().split(/\s+/);

  if (words.length === 1) {
    // If the name has only one word, return it as is
    return teamName.trim();
  } else if (words.length === 2) {
    // If the name has two words, use initials for the first word
    return `${words[0][0].toUpperCase()}${words[0][1]}. ${words[1]}`;
  } else if (words.length === 3) {
    // For three words, use initials for the first two and keep the last
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()} ${
      words[2]
    }`;
  } else {
    // For longer names, use initials for the first two words and keep the last
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()} ${
      words[words.length - 1]
    }`;
  }
}
export function fixDecimal(num, decimalPlace = 2) {
  return num ? Number(NumberToFixed(num, decimalPlace)) : 0;
}

export const LowBalanceCheck = async (betAmount, balance) => {
  let totalBalance =
    Number(balance?.remainingDeposit) + Number(balance?.remainingBonus);
  if (
    Number(balance?.totalBetPlace) <= 0 &&
    Number(balance?.totalBonus) - Number(balance?.remainingBonus) <=
      5 - betAmount &&
    Number(totalBalance) > Number(betAmount) &&
    Number(balance?.remainingDeposit) == 0
  ) {
    return { status: true, msg: "1" };
  }
  if (
    Number(balance?.totalBetPlace) <= 5 &&
    Number(balance?.totalBonus) - Number(balance?.remainingBonus) <
      5 - betAmount &&
    Number(totalBalance) > Number(betAmount) &&
    Number(balance?.remainingDeposit) == 0
  ) {
    return {
      status: false,
      img: "/images/low/1.png",
      msg: "First time user can use only 5 USDT of available bonus, kindly deposit more funds to play!",
    };
  }
  if (
    Number(balance?.totalBetPlace) <= 0 &&
    Number(balance?.totalBonus) - Number(balance?.remainingBonus) === 5 &&
    Number(balance?.remainingDeposit) == 0
  ) {
    return {
      status: false,
      img: "/images/low/1.png",
      msg: "Low balance! Please top up to place a bet.",
    };
  }

  if (Number(totalBalance) < Number(betAmount)) {
    return {
      status: false,
      img: "/images/low/1.png",
      // msg: "Low balance to place a bet.",
      msg:"Insufficient deposit balance! Please add funds to place a bet.",
      response: totalBalance,
    };
  }
  if (Number(balance?.remainingDeposit) < Number(betAmount) / 2) {
    return {
      status: false,
      msg: "First time user can use only 5 USDT of available bonus, kindly deposit more funds to play!",
      img: "/images/low/1.png",
      // msg: "Insufficient deposit balance! Please add funds to place a bet.",
      response: balance?.remainingDeposit,
    };
  }
  return { status: true, msg: "2" };
};
export function NumberToFixed(num, decimalPlace = 4) {
  let number = num;

  const zeroCount = countZerosAfterDecimal(number);
  if (zeroCount === 0 && number === Math.floor(number)) {
    return number.toFixed(zeroCount + 2);
  } else if (zeroCount === 0 || number >= 1) {
    return Number(parseFloat(number).toFixed(decimalPlace));
  } else if (number < 1) {
    return parseFloat(number)
      .toFixed(zeroCount + 2)
      .toString();
  }
}

function countZerosAfterDecimal(number) {
  const parts = number.toString().split(".");
  if (parts.length === 2) {
    const decimalPart = parts[1];
    const nonZeroIndex = decimalPart.search(/[^0]/);

    return nonZeroIndex === -1 ? decimalPart.length : nonZeroIndex;
  }
  return 0;
}

export function getRandomNumber(minNumber, maxNumber) {
  return Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
}
export const validateAccountAddress = async (account) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  try {
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address);
    const balanceOf = await contract.methods.balanceOf(account).call();
    return balanceOf.toString();
  } catch (error) {
    return 0;
  }
};
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export function validUrl(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};

export function formatAsPercentage(num) {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(num / 100);
}
export function counterTimer(endTime) {
  const now = moment();
  const targetDate = moment.unix(endTime);
  const diff = targetDate.diff(now);
  const duration = moment.duration(diff);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return { days, hours, minutes, seconds, diff };
}
export function counterTimeronlySeconds(endTime) {
  const now = moment();
  const targetDate = moment.unix(endTime);
  const diffInSeconds = targetDate.diff(now, "seconds"); // Get total difference in seconds
  return { seconds: diffInSeconds };
}
export function counterTimer1(endTime) {
  const now = moment();
  const targetDate = moment(endTime);
  const diff = targetDate.diff(now);
  const duration = moment.duration(diff);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return { days, hours, minutes, seconds, diff };
}

export const handleLoutFunction = ({ auth }) => {
  window.sessionStorage.removeItem("token");
  auth.userLogIn(false, null);
  localStorage.removeItem("ADMIN");
  localStorage.removeItem("referralCode");
  window.sessionStorage.removeItem("ConnectWallet");
  auth.account && auth.deactivate();
  window.open("/", "_self");
};

export const handleSubmitDataPublishFun = async (dataToSend) => {
  try {
    const response = await postAPIHandler({
      endPoint: "createQuestionOfCricket",
      dataToSend: dataToSend,
    });
    if (response?.data?.responseCode === 200) {
      toast.success(response?.data?.responseMessage);
      return true;
    } else {
      toast.error(response?.data?.responseMessage);
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export const checkImageValidateFormik = (mbSize) => {
  return async (value) => {
    if (!value) return true;
    const maxSize = mbSize * 1024 * 1024; // Convert size from MB to bytes

    const imageInfo = {
      isFile: value instanceof Blob,
      isImageUrl: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/.test(value),
      isBase64: value.startsWith("data:image/"),
    };

    if (imageInfo.isFile) {
      return value.size <= maxSize;
    } else if (imageInfo.isImageUrl) {
      try {
        const response = await fetch(value);
        if (!response.ok) return false;
        const blob = await response.blob();
        return blob.size <= maxSize;
      } catch (error) {
        return false;
      }
    } else if (imageInfo.isBase64) {
      const base64Size = ((value?.length - (value.indexOf(",") + 1)) * 3) / 4; // Calculate base64 size accurately
      return base64Size <= maxSize;
    }

    return false; // Not a valid image URL, base64 image, or file
  };
};
export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0);

  amt = amt?.replace(",", "");
  let arr = amt?.toString().split(".");

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString()
      ).toString();
    } else {
      return numberWithCommas(amt).toString();
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString();
    }
    return "0";
  }
};
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase();
  if (decimal?.includes("e+")) {
    const exponentialSplitted = decimal?.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text?.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal?.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal?.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};
const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 6);

  let arr = str.split(".");

  let numbers = arr[0];
  let decimalNum = "";
  if (arr.length > 1) {
    decimalNum = arr[1];
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalNum}`;
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString();
  if (str?.includes(".")) {
    str = str.slice(0, str.indexOf(".") + (decimals + 1));
  }
  return str;
};
export function numberCompactFormat(value) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
}

export function strtodec(amount, dec) {
  let stringf = "";
  for (var i = 0; i < dec; i++) {
    stringf = stringf + "0";
  }
  return amount + stringf;
}

export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export const listUserHandlerExcel = async ({
  selectFilter,
  totalPages,
  endPoint,
}) => {
  try {
    const res = await getAPIHandler({
      endPoint: endPoint,
      paramsData: {
        page: 1,
        limit: totalPages,
        fromDate: selectFilter.fromDate ? selectFilter.fromDate : undefined,
        toDate: selectFilter.toDate ? selectFilter.toDate : undefined,
        search: selectFilter.search !== "" ? selectFilter.search : undefined,
        transactionType:
          selectFilter.selectType !== "0" ? selectFilter.selectType : undefined,
        transactionStatus:
          selectFilter.statusType !== "" ? selectFilter.statusType : undefined,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data.result.docs;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
export const backgroundImageUrls = [
  "images/green_winner.png",
  "images/yellow_winner.png",
  "images/blue_winner.png",
  "images/green_winner.png",
  "images/red_winner.png",
  "images/blue_winner.png",

  // Add more image URLs as needed
];
