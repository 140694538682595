import React, { memo } from "react";
import { ConnectKitButton } from "connectkit";
import { fixDecimal, sortAddress } from "src/utils";
import { switchChain } from "@wagmi/core";
import { wagmiConfig } from "src/utils/wagmiConfig";
import toast from "react-hot-toast";
import { useBalance , useAccount } from "wagmi";

const CustomConnectButton = ({ payChainID, networkName }) => {
    const { address } = useAccount();
  const handleClick = async (title) => {
    await switchChain(wagmiConfig, { chainId: payChainID });
    toast.success(`${title} switch successfully`);
  };
  // Fetch balance using the useBalance hook
  const {
    data: balance,
    isLoading,
    isError,
  } = useBalance({
    address,
    chainId: payChainID,
  });
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName, chain }) => {
        const isWrongNetwork = chain?.id !== payChainID;
        const buttonClass = `text-[16px] w-full p-1 text-center rounded-lg flex items-center justify-center gap-2 text-2xl text-[#ffffff] hover:opacity-90 active:scale-95 ${
          !isConnected
            ? "bg-[#ecab4f]"
            : isWrongNetwork
            ? "bg-[#FF0000]"
            : "bg-[#ecab4f]"
        }`;

        return (
          <button
            onClick={() => {
              if (isConnected) {
                if (isWrongNetwork) {
                  handleClick(networkName);
                } else {
                  show();
                }
              } else {
                show();
              }
            }}
            className={buttonClass}
            type="button"
          >
            {isConnected ? (
              isWrongNetwork ? (
                `Switch to ${networkName} Network`
              ) : (
                <>
                  {sortAddress(address)}{" "}
                  <p className="ml-2 text-xs whitespace-nowrap">
                    {isLoading
                      ? "Loading..."
                      : isError
                      ? "Error"
                      : ""}
                      {/* : `${fixDecimal(balance?.formatted, 6)} ${balance?.symbol}`} */}
                  </p>
                </>
              )
            ) : (
              "Connect Wallet"
            )}
          </button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

// Memoizing based on payChainID and name (optional, if you want to compare more props)
export default memo(
  CustomConnectButton,
  (prevProps, nextProps) =>
    prevProps.payChainID === nextProps.payChainID &&
    prevProps.networkName === nextProps.networkName
);
