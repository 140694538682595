import React, { useContext } from "react";
import { Box, Typography, Container } from "@material-ui/core";
import { AuthContext } from "src/context/Auth";

export default function PrivacyPolicy() {
  const { staticList } = useContext(AuthContext);
  const privacyPolicyData =
    staticList && staticList.find((item) => item.type === "Privacy-Policy");
  return ( 
      <Container>
          <Typography variant="body2" mb={1} className=""
              dangerouslySetInnerHTML={{
                __html: privacyPolicyData?.description,
              }}
            >
          </Typography>
      </Container> 
  );
}
