import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig, { baseurl } from "src/ApiConfig/ApiConfig";
import { calculateTimeLeft } from "src/utils";
import {
  apiRouterCall,
  getAPIHandler,
  getParticularObjectDataHanndler,
} from "src/ApiConfig/service";
import useSSE from "src/hooks/useSSE";
import { getSpinStatus } from "src/service/spinApi";

export const AuthContext = createContext();
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  // const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({ isGuideView: true });
  const [openDepositFlow, setOpenDepositFlow] = useState(false);
  const [endTime, setEndtime] = useState();
  const [isWalletUpdated, setisWalletUpdated] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [viewWalletData, setViewWalletData] = useState();
  const [tokenBalance, settokenBalance] = useState("");
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [isPotAmount, setisPotAmount] = useState({});
  const [lotteryBuyDisAble, setLotteryBuyDisAble] = useState(false);
  const [blcLoad, setBlcLoad] = useState(true);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [toatlCounts, setTotalCounts] = useState(0);
  const [lastSpinDate, setLastSpinDate] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [staticList, setStaticList] = useState([]);
  const [bonus200Check, setbonus200Check] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [liveCount, setLiveCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rollingData, setRollingData] = useState();
  const [publicAddress, setPublicAddress] = useState("");
  const [isSelectCrypto, setIsSelectCrypto] = useState(true);

  const getStaticApiHandler = async () => {
    setIsLoading(true);
    try {
      const response = await getParticularObjectDataHanndler(
        "listStaticContent"
      );
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        let result = response?.data?.result;
        setStaticList(result);
      } else {
        setIsLoading(false);
        setStaticList([]);
      }
    } catch (error) {
      console.log(error);
      setStaticList([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaticApiHandler();
  }, []);

  const getUserPublicAddress = async () => {
    setIsLoading(true);
    try {
      const response = await getParticularObjectDataHanndler(
        "getWalletAddress"
      );
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        let result = response?.data?.result.walletAddress;
        setPublicAddress(result);
      } else {
        setIsLoading(false);
        setPublicAddress([]);
      }
    } catch (error) {
      console.log("error ----- getWalletAddress ------", error);
      setStaticList();
      setIsLoading(false);
    }
  };

  const HandleExtraSpin = async () => {
    try {
      getSpinStatus().then((res) => {
        const resonse = res?.data?.result;

        if (!resonse) return;
        // for free spin
        setTimeout(() => {
          setLastSpinDate(
            resonse?.lastSpinDate ? resonse?.lastSpinDate : false
          );
          setTotalCount(resonse?.freeSpin ? resonse?.freeSpin : false);
        }, 3000);
      });
    } catch (error) {
      setLastSpinDate(false);
    }
  };

  const { dataSSE, errorSSE, loadingSSE } = useSSE(ApiConfig.getLiveUser); // Replace with your SSE endpoint

  useEffect(() => {
    if (dataSSE) {
      setTotalCounts(dataSSE.count);
      const dataLive = {
        blackJack: dataSSE.blackJack,
        count: dataSSE.count,
        h2h: dataSSE.h2h,
        derby: dataSSE.derby,
        derbyGame: dataSSE.derbyGame,
        home: dataSSE.home,
        lottery: dataSSE.lottery,
      };
      window.localStorage.setItem("totalCounts", JSON.stringify(dataLive));
      setLiveCount(dataLive);
    }
  }, [dataSSE]);

  const viewUserProfile = async (token) => {
    try {
      const response = await axios({
        url: ApiConfig.viewProfile,
        method: "GET",
        headers: {
          token: token,
        },
      });

      if (response.data.responseCode === 200) {
        setUserData(response.data.result);
        localStorage.setItem("userID", response.data.result._id);
        localStorage.setItem("profilePic", response.data.result.profilePic);
        localStorage.setItem("fullName", response.data.result.fullName);
        localStorage.setItem("userName", response.data.result.userName);
        getUserWallletApi();
        setbonus200Check(response.data.result.bonus200Check);
      } else {
        setUserData({});
      }
    } catch (error) {
      window.sessionStorage.removeItem("token");
      setIsLogin(false);
      setSession(null);
      localStorage.removeItem("ADMIN");
      localStorage.removeItem("referralCode");
      localStorage.removeItem("userID");
      localStorage.removeItem("profilePic");
      localStorage.removeItem("fullName");
      localStorage.removeItem("userName");
      window.sessionStorage.removeItem("ConnectWallet");
    }
  };
  const getUserWallletApi = async () => {
    try {
      if (!isLogin) {
        return;
      }
      setisWalletUpdated(!isWalletUpdated);
      // setBlcLoad(true);
      const response = await getAPIHandler({
        endPoint: "viewWallet",
      });
      if (response.data.responseCode === 200) {
        setViewWalletData(response.data.result);
        HandleExtraSpin();

        localStorage.setItem("userBalance", response.data.result.balance);
      } else {
        setViewWalletData();
      }
      setBlcLoad(false);
    } catch (error) {
      setBlcLoad(false);
      console.log(error);
      setViewWalletData();
    }
  };

  // lottery list

  const getGlobalLotteryData = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getLottery,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      getUserPublicAddress();
      viewUserProfile(token);
      window.getUserWalletApi = getUserWallletApi;
    }
  }, [sessionStorage.getItem("token")]);
  useEffect(() => {}, []);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let liveCount1 = window.localStorage.getItem("totalCounts");
  const eyeView = liveCount
    ? liveCount
    : liveCount1
    ? JSON.parse(liveCount1)
    : {
        blackJack: 0,
        count: 0,
        derby: 0,
        derbyGame: 0,
        h2h: 0,
        lottery: 0,
        home: 0,
      };
  useEffect(() => {
    async function getDetail() {
      if (!isLogin) {
        return;
      }
      await axios
        .get(`${baseurl}/api/v1/user/rolling-details`, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((info) => {
          setRollingData(info.data.result);
        });
    }

    getDetail();
  }, [isWalletUpdated, isLogin]);
  let data = {
    userLoggedIn: isLogin,
    rollingData,
    setRollingData,
    bonus200Check,
    userData,
    staticList,
    blcLoad,
    isWalletUpdated,
    toatlCounts,
    eyeView,
    viewWalletData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    // account,
    // deactivate,
    // library,
    // walletConnect: walletConnect,
    isSelectCrypto,
    setIsSelectCrypto,
    tokenBalance,
    isPotAmount,
    openReferralModal,
    setOpenReferralModal,
    lotteryBuyDisAble,
    lastSpinDate,
    totalCount,
    setLotteryBuyDisAble,
    openDepositFlow,
    openSupport,
    publicAddress,
    setOpenSupport: (e) => setOpenSupport(e),
    setOpenDepositFlow: (e) => setOpenDepositFlow(e),
    viewUserProfile: () => viewUserProfile(sessionStorage.getItem("token")),
    getUserWallletApi: getUserWallletApi,
    getGlobalLotteryData: getGlobalLotteryData,
    setOpenDepositModal,
    openDepositModal,
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
