import React, { Fragment, lazy, Suspense } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  useNavigate,
} from "react-router-dom";
import PageLoading from "../component/PageLoading";
import { routes } from "src/routes";
import CoreLayout from "./CoreLayout";
// import { AuthContext } from "../context/Auth";

const renderRoute = () => {
  return routes.map((route, i) => {
    let { element: Element, meta, path, redirect, layout } = route;
    const Layout = route.layout || Fragment;
    const Guard = route.guard ? AuthGuard : Fragment;
    return (
      <>
        <Route
          key={i}
          id={i}
          path={path}
          element={
            <Suspense fallback={<PageLoading />}>
              <Guard>
                <Layout>
                  <Element />
                </Layout>
              </Guard>
            </Suspense>
          }
        />
      </>
    );
  });
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<CoreLayout />}>
      {renderRoute()}

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Route>
  )
);
export default router;

function AuthGuard({ children } = {}) {
  // Provide a default empty object
  const accessToken = window.sessionStorage.getItem("token");

  if (!accessToken) {
    return <Navigate to="/?login" replace />;
  }

  return <>{children}</>;
}

// function AuthGuard(props) {
//   // const navigate = useNavigate();
//   const { children } = props;
//   const accessToken = window.sessionStorage.getItem("token");
//   if (!accessToken) {
//     // window.location.replace("/?login");
//     return (
//       <Route
//         path="/?login"
//         element={lazy(() => import("src/views/pages/Home/index"))}
//         //  element={import("src/views/pages/Home/index")}
//       />
//     );
//   }

//   return <>{children}</>;
// }
