import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import { motion, AnimatePresence } from "framer-motion";
import congratulation from "../ApiConfig/congratulation.json"; // Import your animation JSON
// import { gameSound } from "src/App";
import ApiConfig from "../ApiConfig/ApiConfig";
import axios from "axios";
import { fixDecimal } from "src/utils";

const PartyPopper = ({ isOnParty, partyPopper }) => {
  const containerRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let data = partyPopper[0];
// console.log(" partyPopper data ----- ",data)
  const playAnimation = () => {
    if (containerRef.current) {
      const animation = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: congratulation,
      });

      // try {
      //   gameSound.earnBonus();
      // } catch (error) {
      //   console.error("Audio play failed:", error);
      // }

      animation.addEventListener("complete", () => {
        // animation.destroy();
      });
    }
  };

  useEffect(() => {
    if (isOnParty) {
      setIsVisible(true);
      setTimeout(() => playAnimation(), 100); // Ensure DOM is ready
      const timer = setTimeout(() => {
         updateLotteryPort();
        setIsVisible(false);
      }, 6000); // 1 minutes in milliseconds
      return () => clearTimeout(timer);
    }
  }, [isOnParty]);

  const updateLotteryPort = async () => {
    try {
      await axios({
        method: "PUT",
        url: ApiConfig.updateStatusNotification,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          _id: data?._id,
        },
      });
    } catch (error) {
      console.error("Error updating lottery status:", error);
    }
  };

  // useEffect(() => {
  //   var startAudio = document.getElementById("startAudio");
  //   if (isOnParty && !hasPlayed) {
  //     setTimeout(() => {
  //       startAudio.play();
  //     setHasPlayed(true);
  //     }, 2000);
  //   }
  //   if (hasPlayed) {
  //     startAudio.pause();
  //     startAudio.currentTime = 0;
  //   }
  // }, [isOnParty, hasPlayed]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ x: "100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ type: "spring", stiffness: 100, damping: 15 }}
          className="fixed bottom-5 right-5 z-[9999] bg-gray-900 text-white rounded-lg shadow-lg flex flex-col items-center"
          style={{
            boxShadow: "rgb(0 0 0) 0px -1px 20px 8px", // Yellow box shadow
          }}
        >
          <button
            className="absolute top-1 right-1 text-white text-lg font-bold rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-500"
            onClick={() => setIsVisible(false)}
          >
            ✖
          </button>

          <div className="flex justify-center items-center">
            <div ref={containerRef} className="hidden sm:block w-40 h-24"></div>
            <div className="bg-[url('./assets/imgs/party-pattern.jpg')] rounded-md md:rounded-r-md md:rounded-l-none h-16 md:h-24 flex justify-center items-center shadow-lg">
              <p className="text-[12px] sm:text-base md:text-lg lg:text-xl font-semibold text-center text-white py-1 px-4 md:px-2">
              <span className="sm:hidden">
              Congratulations! {" "}
                </span>
                   You’ve won{" "}
                <span className="text-yellow-400">
                  {fixDecimal(data.winningAmount, 2)} USDT in the {data?.gameType}
                </span>
                !  
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PartyPopper;
