import { createConfig, http } from "wagmi";
import { bsc, bscTestnet, mainnet } from "wagmi/chains";
import { getDefaultConfig } from "connectkit";
import { projectId } from "src/ApiConfig/ApiConfig";

// const projectId = "01f987e67a62911d727636ca70b6b9a8";

const transports = {
  [mainnet.id]: http(mainnet?.rpcUrls?.default?.http[0]),
  [bsc.id]: http(
    "https://bnb-mainnet.g.alchemy.com/v2/iv0upah9F33A5nO2G1zTJa-Ecg4jujCn"
  ),
  [bscTestnet.id]: http(
    "https://bnb-testnet.g.alchemy.com/v2/iv0upah9F33A5nO2G1zTJa-Ecg4jujCn"
  ),
};

export const wagmiConfig = createConfig(
  getDefaultConfig({
    autoConnect: false,
    chains: [mainnet, bsc, bscTestnet],
    transports,
    walletConnectProjectId: projectId,

    appName: "BetByHeart",
    appDescription:
      "BetByHeart | Top Online Games to Earn: Play and Win at Bet By Hearts",
    appUrl: "https://betbyheart.com",
    appIcon: "https://betbyheart.com/images/favicon.png",
  })
);
