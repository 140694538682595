import React, { lazy, useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";
import { MdDashboard } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AuthModal from "./AuthModal";
import Slide from "@material-ui/core/Slide";
import { getQueryParams } from "../../helper/getQueryParam";
import ModalDeposit from "../../views/pages/Profile/ModalDeposit";
import { getAPIHandler } from "src/ApiConfig/service";
import axios from "axios";
import { getPotAmountApi, getSpinStatus } from "../../service/spinApi";
import LivePlayersPopover from "./LivePlayersPopover";
import DepositAndBonusPopover from "./DepositAndBonusPopover";
import ApiConfig from "src/ApiConfig/ApiConfig";
import NextSpinCountDown from "src/component/NextSpinCountDown";
import ModalWith200Bonus from "src/component/ModalWith200Bonus";
import moment from "moment";

const SpinModal = lazy(() => import("./SpinModal.jsx"));
const SpinResultModal = lazy(() => import("./SpinResultModal.jsx"));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },

  popoverContent: {
    borderRadius: "10px",
    color: "#ffffff",
    boxShadow: theme.shadows[3],
    top: "68px !important",
    backgroundColor: "transparent !important",
    background: "none !important",
    boxShadow: "none !important",
    "& .popoverDiv": {
      marginTop: 20,
      backgroundColor: "#1C1F36",
      border: "1px solid #f4a91b54",
      borderRadius: "10px",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: "10px solid #f4a91b54",
      },
    },
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  buttonBoxes: {
    display: "flex",
    alignItems: "center",
    marginRight: "23px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [spinModalOpen, setSpinModalOpen] = useState(false);
  const [spinResultModalOpen, setSpinResultModalOpen] = useState(false);
  const [finalValue, setFinalValue] = useState("Good Luck!");
  const [potAmount, setPotAmount] = useState(null);
  const [bonusSpin, setBonusSpin] = useState(false);
  const [isModal200Open, setIsModal200Open] = useState(false);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [prePathname, setPrePathname] = useState("");

  useEffect(() => {
    setPrePathname(window.location.pathname);
  }, [window.location.pathname]);

  const {
    openDepositModal,
    setOpenDepositModal,
    viewWalletData,
    eyeView,
    openDepositFlow,
    setOpenDepositFlow,
    userData,
  } = auth;

  useEffect(() => {
    if (auth.userData.refferalCodeCheck) {
      auth.setOpenReferralModal(true);
    }
  }, [auth.userData.refferalCodeCheck]);

  useEffect(() => {
    if (location.search === "?login" && !auth.userLoggedIn) {
      setOpen(true);
      return;
    }
    getPotAmountApi().then((res) => {
      const potAmount = res?.data?.result?.potAmount;
      setPotAmount(potAmount || 0);
    });
  }, [auth?.userLoggedIn, location]);

  useEffect(() => {
    if (location && location?.search?.split("?")[1] !== "login") {
      window.localStorage.setItem("referralCode", getQueryParams("refCode"));
    }
  }, [location]);

  useEffect(() => {
    if (!auth.userLoggedIn) {
      return;
    }
    getSpinStatus().then((res) => {
      const resonse = res?.data?.result;
      console.log("spin resonse", resonse);
      if (resonse?.isSpinView === false && resonse?.isFreeSpin) {
        setSpinModalOpen(true);
        setBonusSpin(resonse?.isFreeSpin);
      }
    });
  }, [auth.userLoggedIn]);

  const timeoutIdRef = useRef(null); // Store timeout reference persistently
  useEffect(() => {
    const isEligible =
      auth?.userLoggedIn &&
      auth?.bonus200Check &&
      auth?.userData?.isSpinView &&
      !openDepositModal &&
      !spinModalOpen &&
      ["/my-account", "/referral"].includes(window.location.pathname);

    if (isEligible && !timeoutIdRef.current) {
      const token = sessionStorage.getItem("token"); // Store token before timeout
      timeoutIdRef.current = setTimeout(() => {
        viewUserProfile(token);
        timeoutIdRef.current = null; // Reset reference after execution
      }, 5000);
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [
    auth?.userLoggedIn,
    auth?.bonus200Check,
    auth?.userData?.isSpinView,
    openDepositModal,
    spinModalOpen,
  ]);

  const viewUserProfile = async (token) => {
    if (!token) return; // Prevent API call if token is null

    try {
      const response = await axios.get(ApiConfig.viewProfile, {
        headers: { token },
      });

      if (
        response?.data?.responseCode === 200 &&
        response.data.result?.bonus200Check
      ) {
        if (!openDepositModal) {
          setIsModal200Open(true);
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  let toatlCounts =
    window.location.pathname === "/"
      ? eyeView.home
      : window.location.pathname === "/h2h"
      ? eyeView.h2h
      : window.location.pathname === "/race-view"
      ? eyeView.derbyGame
      : window.location.pathname === "/derby"
      ? eyeView.derby
      : window.location.pathname === "/lottery"
      ? eyeView.lottery
      : window.location.pathname === "/blackjack"
      ? eyeView.blackJack
      : "";

  return (
    <>
      <Box flexGrow={1} />
      <Box className={classes.buttonBoxes}>
        <Box>
          {auth.userLoggedIn && (
            <LivePlayersPopover
              totalCounts={toatlCounts}
              text={"Number of live players"}
              isSmall={false}
            />
          )}
        </Box>
        &nbsp;
        {auth.userLoggedIn && !isMobile && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenDepositModal(true);
              auth.setIsSelectCrypto(false);
            }}
          >
            Deposit
          </Button>
        )}
        &nbsp;
        {auth.userLoggedIn && (
          <Box className="displayCenter" style={{ gap: "5px" }}>
            <DepositAndBonusPopover auth={auth} />
            <Box style={{ marginRight: "8px" }}>
              <SwipeableTemporaryDrawer />
            </Box>
          </Box>
        )}
        {auth.userData.userType === "ADMIN" && (
          <IconButton
            onClick={() => {
              localStorage.setItem("ADMIN", true);
              history("/admin-dashboard");
            }}
            mr={2}
            style={{
              background: "#f4a91b",
              color: "#fff",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            <MdDashboard />
          </IconButton>
        )}
        {!auth.userLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setIsSignUpActive(false);
              setOpen(true);
            }}
          >
            Login
          </Button>
        )}
        {!auth.userLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setIsSignUpActive(true);
              setOpen(true);
            }}
            id="signup-btn"
          >
            Signup
          </Button>
        )}
      </Box>

      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          onClose={() => {
            setOpen(false);
          }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AuthModal
              closeBox={() => setOpen(false)}
              isSignUpActive={isSignUpActive}
              setIsSignUpActive={setIsSignUpActive}
            />
          </DialogContent>
        </Dialog>
      )}

      {isModal200Open && (
        <Dialog
          open={isModal200Open}
          onClose={() => {
            setIsModal200Open(false);
          }}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          maxWidth="sm"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <div className={`lastSpinDate pointer-events-none`}>
              <NextSpinCountDown
                endTime={moment(auth?.userData?.createdAt).add(24, "h").unix()}
                text={"Ends in "}
              />
            </div>

            <ModalWith200Bonus
              closeBox={() => {
                setIsModal200Open(false);
                auth.viewUserProfile(sessionStorage.getItem("token"));
              }}
              handleOpenDeposit={() => {
                auth.setOpenDepositModal(true);
                auth.setIsSelectCrypto(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {openDepositModal && (
        <ModalDeposit
          open={openDepositModal}
          closeBox={() => {
            setOpenDepositModal(false);
            auth.viewUserProfile(sessionStorage.getItem("token"));
          }}
          getAPIHandler={getAPIHandler}
          useEffect={useEffect}
          axios={axios}
          viewWalletData={viewWalletData}
          openDepositFlow={openDepositFlow}
          setOpenDepositFlow={setOpenDepositFlow}
          prePathname={prePathname}
        />
      )}

      {spinModalOpen && (
        <Dialog
          open={spinModalOpen}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          // onClose={() => {
          //   setSpinModalOpen(false);
          // }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {auth?.lastSpinDate && (
              <div className={`lastSpinDate pointer-events-none`}>
                <NextSpinCountDown
                  endTime={moment(auth?.lastSpinDate).add(7, "d").unix()}
                  text={"Your next free spin drops in "}
                />
              </div>
            )}
            <SpinModal
              setSpinResultModalOpen={setSpinResultModalOpen}
              spinResultModalOpen={spinResultModalOpen}
              setSpinModalOpen={setSpinModalOpen}
              setFinalValue={setFinalValue}
              finalValue={finalValue}
              potAmount={potAmount}
              auth={auth}
              bonusSpin={bonusSpin}
              handleOpenDeposit={() => {
                auth.setOpenDepositModal(true);
                auth.setIsSelectCrypto(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {spinResultModalOpen && (
        <Dialog
          open={spinResultModalOpen}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          // onClose={() => {
          //   setSpinResultModalOpen(false);
          // }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <SpinResultModal
              setSpinResultModalOpen={setSpinResultModalOpen}
              finalValue={finalValue}
              setSpinModalOpen={setSpinModalOpen}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
