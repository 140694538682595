import React from "react";
import LazyImage from "src/component/LazyImage";

const Logo = (props) => {
  return (
    <img
      src="/images/logo.png"
      // height="69px"
      // width="70px"
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
