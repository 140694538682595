import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ src, alt, height, width, ...props }) => {
  return (
    <div>
      <LazyLoadImage
        alt={alt}
        height={height}
        width={width}
        src={src}
        effect="blur" // Effects: 'blur', 'black-and-white', 'opacity'
        loading="eager"
        threshold={0}
        {...props}
        onDragStart={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default LazyImage;
