import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { Link,  useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { AiFillHome } from "react-icons/ai";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";
import { getQueryParams } from "../../helper/getQueryParam";
import LivePlayersPopover from "../HomeLayout/LivePlayersPopover";
import DepositAndBonusPopover from "../HomeLayout/DepositAndBonusPopover";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: " 5px 0px",
    "& .TopbarButton": {
      margin: "0px !important",
      marginRight: "0px !important",
      fontSize: "14px",
      padding: "8px 18px !important",
      [theme.breakpoints.down("sm")]: {
        padding: "0px !important",
        border: "none",
        fontSize: "11px",
      },
    },
  },
  hedaerTopbar: {
    [theme.breakpoints.down("sm")]: {
      height: "37px",
    },
  },
  profileBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const { toolbar, hedaerTopbar, profileBox } = useStyles();
  // const history = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const { eyeView } = auth;

  useEffect(() => {
    if (location) {
      window.localStorage.setItem("referralCode", getQueryParams("refCode"));
    }
  }, [location]);

  let toatlCounts =
    window.location.pathname === "/"
      ? eyeView.home
      : window.location.pathname === "/h2h"
      ? eyeView.h2h
      : window.location.pathname === "/race-view"
      ? eyeView.derbyGame
      : window.location.pathname === "/derby"
      ? eyeView.derby
      : window.location.pathname === "/lottery"
      ? eyeView.lottery
      : window.location.pathname === "/blackjack"
      ? eyeView.blackJack
      : "";

  const screenName = (() => {
    switch (window.location.pathname) {
      case "/":
        return "home";
      case "/h2h":
        return "h2h";
      case "/race-view":
        return "derbyGame";
      case "/derby":
        return "derby";
      case "/lottery":
        return "lottery";
      case "/blackjack":
        return "blackJack";
      default:
        return "home";
    }
  })();



  return (
    <AppBar
      position={window.location.pathname !== "/" ? "relative" : "absolute"}
      elevation={0}
      style={{
        backgroundColor: "transparent",
        border: "none",
      }}
      className={hedaerTopbar}
    >
      <Container maxWidth="fixed">
        <Toolbar className={toolbar}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;
            <IconButton
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                window.terminateCrtUser("home");
              }}
            >
              <AiFillHome />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
            <Box className="centerLogoBox balckjacklogo">
              <Link to="/">
                <Logo className="logoImg" />
              </Link>
            </Box>

            <Box className="displayCenter" style={{ gap: "7px" }}>
              {auth.userLoggedIn && (
                <>
                  <div id="eyeHover">
                    <LivePlayersPopover
                      totalCounts={toatlCounts}
                      text={"Number of live players"}
                      isSmall={false}
                    />
                  </div>
                  <DepositAndBonusPopover auth={auth} />
                  <Box style={{ marginRight: "8px" }} className={profileBox}>
                    <SwipeableTemporaryDrawer isBlackJack={true} />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
