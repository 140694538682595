import React from "react";

export default function ModalWith200Bonus({ closeBox, handleOpenDeposit }) {
  return (
    <div
      className="spin-modal1 flex space-between items-center justify-between "
      style={{
        backgroundImage: `url(${"/images/spinBanner1.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src="/images/upTo200Bonus1.jpg" alt={"up to 200% bonus"} />
      <div
        className={`spinCloseButton pointer-events-auto`}
        onClick={() => {
          closeBox(false);
        }}
      >
        <img src="/images/closeIcon.svg" alt="closeIcon" width={30} />
      </div>
      <div className={" absolute bottom-0 "}>
   
        <button
          variant="contained"
          onClick={() => {
            handleOpenDeposit();
            closeBox(false);
          }}
          color="primary" 
          className="depositButton text-[#520d07] font-extrabold bg-[#f9f8ab] border-4 border-[#e9ca60] px-8 py-3 text-sm leading-6 whitespace-pre rounded-full shadow-[0px_3px_1px_-2px_rgba(0,0,0,0.2),0px_2px_2px_0px_rgba(0,0,0,0.14),0px_1px_5px_0px_rgba(0,0,0,0.12)] box-border transition duration-250 ease-in-out hover:bg-[#e9ca60] hover:border-[#f9f8ab] font-readex"
          // className="depositButton"
        >
          DEPOSIT NOW
        </button>
      </div>
    </div>
  );
}
