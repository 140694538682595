import useApi from "./useApi";
export const usePost = () => {
  const { data, error, loading, refetch } = useApi();

  const postData = async (url, payload) => {
    try {
      await refetch(url, { method: "POST", data: payload });
    } catch (err) {
      console.error("Error posting data:", err);
    }
  };

  return { dataPost:data, errorPost: error, loadingPost: loading, postData };
};
