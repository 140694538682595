import { useEffect } from "react";

const useScript = (url, options = {}) => {
  const { async = false, defer = false, delay = 0 } = options;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = async;
    script.defer = defer;
    script.crossOrigin = "anonymous";

    if (delay > 0) {
      const timer = setTimeout(() => {
        document.body.appendChild(script);
      }, delay);

      return () => {
        clearTimeout(timer);
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    } else {
      document.body.appendChild(script);
    }

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [url, async, defer, delay]);
};

export default useScript;
