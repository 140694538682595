import toast from "react-hot-toast";
import { formatEther, parseEther, parseUnits } from "viem";
import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useBalance,
} from "wagmi";

export const useWriteContract = () => {
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient, isLoading, refetch } = useWalletClient();
  const { data: ethBalance } = useBalance({ address });

  /**
   * Open MetaMask on mobile using deep link
   */
  const openMetaMaskDeepLink = () => {
    const metamaskDeepLink = "https://metamask.app.link/wc";
    const metamaskAppUrl = "metamask://wc";
    const userAgent = navigator.userAgent;

    if (/Mobi|Android/i.test(userAgent)) {
      window.location.href = metamaskAppUrl;
      setTimeout(() => {
        window.location.href = metamaskDeepLink;
      }, 1000);
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = metamaskAppUrl;
      setTimeout(() => {
        window.location.href = metamaskDeepLink;
      }, 1000);
    }
  };

  /**
   * Send ETH to a recipient with error handling and validation
   */
  const sendEth = async (recipient, amount) => {
    await refetch();

    if (!walletClient || isLoading) {
      toast.error("Please connect your wallet");
      return {
        error: "Please connect your wallet.",
        success: false,
      };
    }
    if (!isConnected) {
      toast.error("Wallet not connected");
      return {
        error: "Wallet not connected.",
        success: false,
      };
    }
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      toast.error("Invalid amount");
      return {
        error: "Invalid amount.",
        success: false,
      };
    }
    if (
      ethBalance &&
      parseFloat(amount) > parseFloat(formatEther(ethBalance.value))
    ) {
      toast.error("Insufficient balance");
      return {
        error: "Insufficient balance.",
        success: false,
      };
    }

    try {
      openMetaMaskDeepLink();

      const txHash = await walletClient.sendTransaction({
        to: recipient,
        value: parseEther(amount),
      });

      toast.success(`Transaction successful!`);
      return {
        error: null,
        success: true,
        txHash: txHash,
      };
    } catch (error) {
      toast.error(error.shortMessage || error.message);
      return {
        error: error.shortMessage || error.message,
        success: false,
        txHash: null,
      };
    }
  };

  /**
   * Send ERC-20 Token with error handling and validation
   */
  const sendErc20Token = async (
    tokenAddress,
    recipient,
    amount,
    decimals = 18
  ) => {
    await refetch();

    if (!walletClient || isLoading) {
      toast.error("Please connect your wallet");
      return {
        error: "Please connect your wallet.",
        success: false,
      };
    }
    if (!isConnected) {
      toast.error("Wallet not connected");
      return {
        error: "Wallet not connected.",
        success: false,
      };
    }
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      toast.error("Invalid amount");
      return {
        error: "Invalid amount.",
        success: false,
      };
    }

    try {
      openMetaMaskDeepLink();

      const ERC20_ABI = [
        {
          name: "balanceOf",
          type: "function",
          stateMutability: "view",
          inputs: [{ name: "account", type: "address" }],
          outputs: [{ name: "balance", type: "uint256" }],
        },
        {
          name: "transfer",
          type: "function",
          stateMutability: "nonpayable",
          inputs: [
            { name: "to", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          outputs: [{ name: "", type: "bool" }],
        },
      ];

      const amountInSmallestUnit = parseUnits(amount, decimals);

      // Fetch token balance
      const balance = await publicClient.readContract({
        address: tokenAddress,
        abi: ERC20_ABI,
        functionName: "balanceOf",
        args: [address],
      });

      if (!balance || amountInSmallestUnit > balance) {
        toast.error("Insufficient token balance");
        return {
          error: "Insufficient token balance.",
          success: false,
        };
      }

      const txHash = await walletClient.writeContract({
        address: tokenAddress,
        abi: ERC20_ABI,
        functionName: "transfer",
        args: [recipient, amountInSmallestUnit],
      });

      toast.success(`Token sent successfully!`);
      return {
        error: null,
        success: true,
        txHash: txHash,
      };
    } catch (error) {
      toast.error(error.shortMessage || error.message);
      return {
        error: error.shortMessage || error.message,
        success: false,
      };
    }
  };

  return {
    sendEth,
    sendErc20Token,
  };
};
