import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import ApiConfig, { baseurlSocket } from "src/ApiConfig/ApiConfig";
import { AuthContext } from "./Auth";
import PartyPopper from "src/component/PartyPopper";
import { usePost } from "src/hooks/usePost";

export const WebSocketContext = createContext();

const webS = new WebSocket(baseurlSocket);

export default function WebSocketProvider(props) {
  const auth = useContext(AuthContext);
  const [coinType, setCoinType] = useState("BTC");
  const { userData, userLoggedIn } = auth;
  const [liveCricketMatch, setLiveCricketMatch] = useState([]);
  const [liveFootBallMatch, setLiveFootBallMatch] = useState([]);
  const [partyPopper, setPartyPopper] = useState([]);
  const webSocketRef = useRef(null);
  const [userLiveData1, setUserLiveData1] = useState([]);
  const screenName = (() => {
    switch (window.location.pathname) {
      case "/":
        return "home";
      case "/h2h":
        return "h2h";
      case "/race-view":
        return "derbyGame";
      case "/derby":
        return "derby";
      case "/lottery":
        return "lottery";
      case "/blackjack":
        return "blackJack";
      default:
        return "home";
    }
  })();

  const { dataPost, errorPost, loadingPost, postData } = usePost();

  const handleSubmit = () => {
    if (auth.userLoggedIn) {
      postData(ApiConfig.addLiveUser, { screenName: screenName });
    }
  };

  useEffect(() => {
    if (screenName) {
      const interval = setInterval(() => {
        handleSubmit();
      }, 5000); // 5000ms = 5 seconds
      return () => clearInterval(interval);
    }
  }, [screenName, auth.userLoggedIn]);

  const [btc, setBtc] = useState({
    price: null,
    checkDown: undefined,
  });
  const [eth, setEth] = useState({
    price: null,
    checkDown: undefined,
  });
  const [trx, setTrx] = useState({
    price: null,
    checkDown: undefined,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      webS.onopen = () => {
        const dataToSend = {
          requestType: "getMarketPriceCMC",
          coinType: "All",
          fromDate: new Date(),
        };

        webS.send(JSON.stringify(dataToSend));
        let prevBtcVal = -1;
        let prevBTC = -1;
        let prevCloseTime_btc = new Date().getTime();
        let prevEthVal = -1;
        let prevETH = -1;
        let prevCloseTime_eth = new Date().getTime();
        let prevTrxVal = -1;
        let prevTRX = -1;
        let prevCloseTime_trx = new Date().getTime();

        webS.onmessage = async (event) => {
          let obj = JSON.parse(event.data);
          const result = obj.result;

          if (Array.isArray(result) && result.length === 0) return;

          let checkUpAndDown = false;
          let checkUpAndDownEth = false;
          let checkUpAndDownTRX = false;
          const btcCloseVal =
            result?.filteredBTCData?.quotes[0]?.quote?.USD?.close;
          const ethCloseVal =
            result?.filteredETHBTCData?.quotes[0]?.quote?.USD?.close;
          const trxCloseVal =
            result?.filteredTRXBTCData?.quotes[0]?.quote?.USD?.close;

          const btc_closingtime = new Date(
            result?.filteredBTCData?.quotes[0]?.time_close
          ).getTime();
          const eth_closingtime = new Date(
            result?.filteredETHBTCData?.quotes[0]?.time_close
          ).getTime();
          const trx_closingtime = new Date(
            result?.filteredTRXBTCData?.quotes[0]?.time_close
          ).getTime();

          if (prevBtcVal === -1) {
            prevBtcVal = btcCloseVal;
            prevBTC = btcCloseVal;
            prevCloseTime_btc = btc_closingtime;
          } else if (
            btcCloseVal !== prevBTC ||
            btcCloseVal > prevBTC ||
            btcCloseVal < prevBTC
          ) {
            checkUpAndDown = btcCloseVal > prevBTC;
            if (prevCloseTime_btc !== btc_closingtime) {
              prevBTC = prevBtcVal;
              prevBtcVal = btcCloseVal;
              prevCloseTime_btc = btc_closingtime;
            }
          }

          // Update BTC state
          setBtc({
            price: btcCloseVal,
            checkDown: checkUpAndDown,
          });

          // price up down login for eth
          if (prevEthVal === -1) {
            prevEthVal = ethCloseVal;
            prevETH = ethCloseVal;
            prevCloseTime_eth = eth_closingtime;
          } else if (
            ethCloseVal !== prevETH ||
            ethCloseVal > prevETH ||
            ethCloseVal < prevETH
          ) {
            checkUpAndDownEth = ethCloseVal > prevETH;
            if (prevCloseTime_eth !== eth_closingtime) {
              prevETH = prevEthVal;
              prevEthVal = ethCloseVal;
              prevCloseTime_eth = eth_closingtime;
            }
          }

          // Update ETH state
          setEth({
            price: ethCloseVal,
            checkDown: checkUpAndDownEth,
          });

          // price up down login for eth
          if (prevTrxVal === -1) {
            prevTrxVal = trxCloseVal;
            prevTRX = trxCloseVal;
            prevCloseTime_trx = trx_closingtime;
          } else if (
            trxCloseVal !== prevTRX ||
            trxCloseVal > prevTRX ||
            trxCloseVal < prevTRX
          ) {
            checkUpAndDownTRX = trxCloseVal > prevTRX;
            if (prevCloseTime_trx !== trx_closingtime) {
              prevTRX = prevTrxVal;
              prevTrxVal = trxCloseVal;
              prevCloseTime_trx = trx_closingtime;
            }
          }

          setTrx({
            price: trxCloseVal,
            checkDown: checkUpAndDownTRX,
          });

          setLoading(false);
        };
      };
      return () => {
        webS.close();
      };
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!userData?._id) return;

    let mounted = true;
    let reconnectAttempts = 0;

    function handleUserWebSocket() {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }

      const web = new WebSocket(baseurlSocket);
      webSocketRef.current = web;

      web.onopen = () => {
        reconnectAttempts = 0; // Reset reconnection attempts on successful connection
        web.send(
          JSON.stringify({
            requestType: "winningNotification",
            userId: userData?._id,
          })
        );
      };

      web.onmessage = async (event) => {
        if (!mounted) return;

        try {
          const result = JSON.parse(event.data);
          if (Array.isArray(result) && result.length === 0) return;

          console.log("Winning notification received:", result);
          setTimeout(() => {
            setPartyPopper(result);
          }, 1000);
        } catch (error) {
          console.error("Error processing winning notification:", error);
        }
      };

      web.onerror = (error) => {
        console.error("WebSocket error:", error);
        attemptReconnect(); // Attempt to reconnect on error
      };

      web.onclose = () => {
        console.log("User WebSocket closed");
        attemptReconnect(); // Attempt to reconnect on close
      };
    }

    function attemptReconnect() {
      if (reconnectAttempts < 5) {
        // Maximum of 5 reconnection attempts
        reconnectAttempts++;
        const delay = Math.pow(2, reconnectAttempts) * 1000; // Exponential backoff (2^n * 1000ms)
        setTimeout(() => {
          if (mounted) handleUserWebSocket(); // Reconnect only if component is still mounted
        }, delay);
      } else {
        console.error("Max reconnect attempts reached");
      }
    }

    handleUserWebSocket();

    return () => {
      mounted = false;
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, [userData?._id, userLoggedIn]);

  useEffect(() => {
    const web = new WebSocket(baseurlSocket);

    web.onopen = () => {
      const dataToSend = {
        userId: auth?.userData?._id,
        requestType: "liveGameList",
        gameType: "CRYPTO",
        // page,
        limit: 5,
        types: "LIVE",
        fromDate: new Date(Date.now() - 36 * 60 * 60 * 1000).toISOString(),
        toDate: new Date().toISOString(),
      };
      web.send(JSON.stringify(dataToSend));
      web.onmessage = async (event) => {
        if (event.data !== "[object Promise]" && event.data !== "null") {
          try {
            const obj = JSON.parse(event.data);
            if (obj.responseCode === 200) {
              setUserLiveData1(
               [ obj?.result?.docs?.find((item) => coinType === item?.coinName)]
              );
            } else {
              setUserLiveData1([]);
            }
          } catch (err) {
            setUserLiveData1([]);
            console.error("Failed to parse WebSocket message:", err);
          }
        }
      };
    };

    web.onerror = (err) => {
      console.error("WebSocket error:", err);
      // setIsLoading(false);
      // setCallSocket(false);
    };

    web.onclose = () => {
      console.warn("WebSocket connection closed.");
    };

    return () => {
      if (
        web.readyState === WebSocket.OPEN ||
        web.readyState === WebSocket.CONNECTING
      ) {
        web.close();
      }
    };
  }, [coinType]);

  // const handleWebSocketMessage = 

  const data = {
    btc,
    eth,
    trx,
    loading,
    liveFootBallMatch,
    userLiveData1,
    setUserLiveData1,
    coinType,
    setCoinType,
    setLiveFootBallMatch: (e) => setLiveFootBallMatch(e),
    liveCricketMatch,
    setLiveCricketMatch: (e) => setLiveCricketMatch(e),
    partyPopper,
    setPartyPopper: (e) => setPartyPopper(e),
  };

  return (
    <WebSocketContext.Provider value={data}>
      {props.children}

      {/* {partyPopper && partyPopper.length > 0 && ( */}
      <PartyPopper
        isOnParty={partyPopper.length > 0}
        partyPopper={partyPopper}
      />
      {/* )} */}
    </WebSocketContext.Provider>
  );
}
