import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import { RxCross2 } from "react-icons/rx";
import { AuthContext } from "src/context/Auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TermAndConditionAndPrivacyPolicyStaticModal({ open, setHide, type }) {
  const { staticList } = useContext(AuthContext);
  const staticData =
    staticList &&
    staticList.find(
      (item) =>
        item.type ===
        (type === "term-condition" ? "Terms-Conditions" : "Privacy-Policy")
    );

  const handleDeny = () => {
    setHide(false);
  };
  const height = window.innerHeight < 680;
  const renderStaticContent = useCallback(() => {
    return (
      <Container>
        <Typography
          variant="body2"
          mb={1}
          className=""
          dangerouslySetInnerHTML={{
            __html: staticData?.description,
          }}
        ></Typography>
      </Container>
    );
  }, [type]);

  return (
    open && (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        className="noPadding"
        onClose={() => {
          setHide(false);
        }}
        maxWidth="lg"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ padding: height ? "8px 12px" : "16px 24px" }}>
          <Box
            className={`check-term-condition flex flex-col gap-y-${
              height ? 1 : 2
            } justify-center items-center`}
          >
            <img
              src="images/logo.png"
              className="horsetopBox"
              alt=""
              style={{ maxWidth: height ? "50px" : "70px", margin: "auto" }}
            />
            <Typography
              variant="h2"
              style={{
                fontSize: height ? "16px" : "20px",
                fontWeight: "600",
                color: "orange",
                lineHeight: height ? "22px" : "30px",
              }}
            >
              {type === "term-condition"
                ? "Term and Conditions"
                : "Privacy Policy"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className="check-term-condition flex flex-col gap-y-2 justify-center items-center py-5 p-2">
            <RxCross2
              className="closeIcon"
              onClick={() => {
                setHide(false);
              }}
            />

            <Box className="flex flex-col justify-center items-center w-full gap-x-2">
              {renderStaticContent()}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              background: "transparent",
              borderColor: "orange",
              padding: height ? "10px 40px" : "13px 60px",
            }}
            onClick={handleDeny}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export default React.memo(TermAndConditionAndPrivacyPolicyStaticModal);
