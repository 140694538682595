import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core"; 
import TopBar from "./TopBar";
import Footer from "./Footer";
import { AuthContext } from "../../context/Auth";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    position: "relative",
    zIndex: "9",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles(); 
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [window.location.href])


  const { getUserWallletApi, openSupport } = useContext(AuthContext);



  const loadZendeskScript = async (delay = 0) => {
    if (document.getElementById("ze-snippet")) return;
  
    await new Promise((resolve) => setTimeout(resolve, delay)); // Delay before loading script
  
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=528acb82-3f6e-4123-b783-2a892a77848b";
    script.async = true;
  
    // Handle script load and error events
    script.onload = () => {
      console.log("Zendesk script loaded successfully");
    };
  
    script.onerror = () => {
      console.error("Failed to load the Zendesk script");
    };
  
    document.head.appendChild(script);
  };

  useEffect(() => {
    const initializeZendesk = async () => {
      await loadZendeskScript(2000); // Example: 2-second delay
    };

    initializeZendesk();
  }, []);


  useEffect(() => {
    getUserWallletApi();
  }, [])
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          window.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
